import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const NewTransferCheck: React.FC = () => {
  const {
    global: { newTransferCreated, newTransferStatusChanged },
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
    },
  } = useSelector((state) => state);

  const {
    setSoundPlaying,
    getNewTransfersThunk,
    setNewTransferCreated,
    setNewTransferStatusChanged,
  } = useActions();

  const [_, __, restartTimeout] = useAsyncTimeout(
    async () => {
      if (
        role_yonetim_listesi['Yeni Havale Yatırımları / Sonuçlandırma'] ===
          '1' &&
        userLoaded
      ) {
        await getNewTransfersThunk();
        restartTimeout();
      }
    },
    5000,
    [role_yonetim_listesi, userLoaded]
  );

  useEffect(() => {
    if (
      role_yonetim_listesi['Yeni Havale Yatırımları / Sonuçlandırma'] === '1' &&
      userLoaded
    ) {
      getNewTransfersThunk();
    }
  }, [role_yonetim_listesi, userLoaded]);

  useEffect(() => {
    if (
      (newTransferCreated || newTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Havale Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1'
    ) {
      if (newTransferStatusChanged) {
        setSoundPlaying('update');
        document.title = 'Müşteri Yatırdım butonuna bastı...';
      } else if (newTransferCreated) {
        setSoundPlaying('bip');
        document.title = 'Yeni Yatırım isteği...';
      }
    }
  }, [newTransferCreated, newTransferStatusChanged, role_yonetim_listesi]);

  useAsyncTimeout(
    () => {
      if (newTransferCreated || newTransferStatusChanged) {
        setNewTransferCreated(false);
        setNewTransferStatusChanged(false);
      }
      document.title = 'Backoffice | Kralpay';
    },
    2000,
    [newTransferCreated, newTransferStatusChanged]
  );
  
  return <></>;
};

export default NewTransferCheck;
