import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PapelRegisterInfoFields,
  PapelRegisterInfoParams,
} from '../../../pages/PapelRegisters/types';
import { makeRequestThunk } from '../../../utils/request';

export const getPapelRegisterInfoThunk = createAsyncThunk(
  'global/getPapelRegisterInfo',
  async (
    { id }: PapelRegisterInfoParams,
    { rejectWithValue }
  ): Promise<PapelRegisterInfoFields> => {
    const { data, rejected } = await makeRequestThunk<PapelRegisterInfoFields>(
      {
        method: 'get',
        url: `/api/v1/papelKasa/${id}`,
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
