import React, { ReactNode, useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import { useActions, useSelector } from '../../redux/hooks';
import Button, { Color, Icon } from '../../components/Button/Button';
import { OrderType } from '../../components/DataTable/types';
import DataTable from '../../components/DataTable/DataTable';
import useDataTable from '../../components/DataTable/DataTable';
import { makeRequest } from '../../utils/request';

interface NewTransferDetailTableProps {
  id: string;
}

const NewPapelTransferDetailTable: React.FC<NewTransferDetailTableProps> = ({
  id,
}) => {
  const { newTransferPapelRegisters, newTransferPapelRegistersLoading } =
    useSelector((state) => state.global);

  const {
    getNewPapelTransferRegistersThunk,
    getNewPapelTransferInfoThunk,
    getNewPapelTransfersThunk,
    showMessage,
    setLoading,
  } = useActions();

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);
  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');

  const [isim, setName] = useState('');
  const [hesap_no, setAccountNo] = useState('');
  const [iban, setIban] = useState('');

  const fetchNewTransferPapelRegistersList = () => {
    getNewPapelTransferRegistersThunk({
      isim,
      hesap_no,
      iban,
      page,
      pageCount: pagination,
      order,
      column,
  });
  };

  useEffect(() => {
    fetchNewTransferPapelRegistersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, column, pagination]);

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    if ([3, 6, 7, 8].includes(index)) {
      return value + ' TL';
    }
    if (index === 9) {
      if (value === true) {
        return 'Aktif';
      } else if (value === false) return 'Pasif';
    }
  };

  const onRowClick = async (index: number, row: React.ReactNode[]) => {
    const result = window.confirm(
      'Bu hesabı göndermek istediğinize emin misiniz?'
    );
    if (result) {
      setLoading(true);
      const { rejected } = await makeRequest(
        {
          method: 'patch',
          url: `/api/v1/transactions/papelDeposits/${id}`,
          data: {
            type: 'Hesap Gonderildi',
            papelKasa: row[0],
          },
        },
        showMessage
      );
      if (!rejected) {
        getNewPapelTransferInfoThunk({ id });
        getNewPapelTransfersThunk();
      }
      setLoading(false);
    }
  };

  const [itWorks, setItWorks] = useState(false);

  useEffect(() => {
    // reference must be same for addEventListener and removeEventListener
    // = can't use inline arrow function!
    function listenerNewTransferPapelRegister(event: { code: string; }) {
        if (event.code === 'Enter') {
            setItWorks(!itWorks);
            fetchNewTransferPapelRegistersList();
        }
    }
    fetchNewTransferPapelRegistersList();

    document.addEventListener('keypress', listenerNewTransferPapelRegister);
    return () => {
    document.removeEventListener('keypress', listenerNewTransferPapelRegister);
    }
  }, [!itWorks]);

  const DataTable = useDataTable<12>();

  return (
    <Row width="100%">
      <Column width="100%">
        <Row width="100%">
          <Column width="25%">
            <LabeledContainer label="İsim">
              <input
                value={isim}
                onChange={(event) => setName(event.target.value)}
              />
            </LabeledContainer>
          </Column>
          <Column width="25%">
            <LabeledContainer label="Hesap No">
              <input
                type="number"
                value={hesap_no}
                onChange={(event) => setAccountNo(event.target.value)}
              />
            </LabeledContainer>
          </Column>
          <Column width="50%" height="80px">
            <Row height="40px" padding="0">
              <Button
                onClick={() => {
                  setItWorks(!itWorks);
                  if (page === 1) fetchNewTransferPapelRegistersList();
                  setCurrentPage(1);
                }}
                color={Color.orange}
                icon={Icon.search}
                loading={newTransferPapelRegistersLoading}
              >
                Filtrele
              </Button>
            </Row>
          </Column>
        </Row>
        <Row width="100%">
          <DataTable
            onPageUpdate={(pageNumber, orderColumn, orderType, pagination) => {
              setCurrentPage(pageNumber);
              setOrderColumn(orderColumn);
              setOrderType(orderType);
              setPagination(pagination);
            }}
            value={newTransferPapelRegisters}
            loading={newTransferPapelRegistersLoading}
            page={page}
            pagination={pagination}
            onColumnRender={onColumnRender}
            onRowClick={onRowClick}
          />
        </Row>
      </Column>
    </Row>
  );
};

export default NewPapelTransferDetailTable;
