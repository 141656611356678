// src/pages/ErrorPage.tsx
import React from 'react';

export const ErrorPage = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>403 - Access Denied</h1>
      <p>Bu kova görüntülenemiyor.</p>
    </div>
  );
};