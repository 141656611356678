import React, { useEffect } from 'react';
import useSound from 'use-sound';
import bipSound from './bipsound.mp3';
import updateSound from './updatesound.mp3';
import alertSound from './alertsound.mp3';
import { useActions, useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const Sound: React.FC = () => {
  const {
    notification: { activeSound },
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
    },
  } = useSelector((state) => state);

  const { setSoundPlaying } = useActions();

  const [playBip] = useSound(bipSound);
  const [playUpdate] = useSound(updateSound);
  const [playAlert] = useSound(alertSound);

  useEffect(() => {
    if (
      activeSound &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      userLoaded
    ) {
      if (activeSound === 'bip') {
        playBip();
      } else if (activeSound === 'update') {
        playUpdate();
      } else if (activeSound === 'alert') {
        playAlert();
      }
    }
  }, [playBip, playUpdate, playAlert, activeSound, role_yonetim_listesi, userLoaded]);

  useAsyncTimeout(
    () => {
      setSoundPlaying('');
    },
    2000,
    [activeSound]
  );

  return <></>;
};

export default Sound;
