import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const NewPapelTransferCheck: React.FC = () => {
  const {
    global: { newPapelTransferCreated, newPapelTransferStatusChanged },
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
    },
  } = useSelector((state) => state);

  const {
    setSoundPlaying,
    getNewPapelTransfersThunk,
    setNewPapelTransferCreated,
    setNewPapelTransferStatusChanged,
  } = useActions();

  const [_, __, restartTimeout] = useAsyncTimeout(
    async () => {
      if (
        role_yonetim_listesi['Yeni Papel Yatırımları / Sonuçlandırma'] ===
          '1' &&
        userLoaded
      ) {
        await getNewPapelTransfersThunk();
        restartTimeout();
      }
    },
    3000,
    [role_yonetim_listesi, userLoaded]
  );

  useEffect(() => {
    if (
      role_yonetim_listesi['Yeni Papel Yatırımları / Sonuçlandırma'] === '1' &&
      userLoaded
    ) {
      getNewPapelTransfersThunk();
    }
  }, [role_yonetim_listesi, userLoaded]);

  useEffect(() => {
    if (
      (newPapelTransferCreated || newPapelTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Papel Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1'
    ) {
      if (newPapelTransferStatusChanged) {
        setSoundPlaying('update');
        document.title = 'Müşteri Yatırdım butonuna bastı...';
      } else if (newPapelTransferCreated) {
        setSoundPlaying('bip');
        document.title = 'Yeni Yatırım isteği...';
      }
    }
  }, [
    newPapelTransferCreated,
    newPapelTransferStatusChanged,
    role_yonetim_listesi,
  ]);

  useAsyncTimeout(
    () => {
      if (newPapelTransferCreated || newPapelTransferStatusChanged){
        setNewPapelTransferCreated(false);
        setNewPapelTransferStatusChanged(false);
      }
      document.title = 'Backoffice | Kralpay';
    },
    2000,
    [newPapelTransferCreated, newPapelTransferStatusChanged]
  );

  return <></>;
};

export default NewPapelTransferCheck;
