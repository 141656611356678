import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  WithdrawPapelInfoFields,
  WithdrawPapelInfoParams,
} from '../../../pages/WithdrawsPapel/types';
import { makeRequestThunk } from '../../../utils/request';

export const getWithdrawPapelInfoThunk = createAsyncThunk(
  'global/getWithdrawPapelInfo',
  async (
    { id, hidden }: WithdrawPapelInfoParams,
    { rejectWithValue }
  ): Promise<WithdrawPapelInfoFields> => {
    const { data, rejected } = await makeRequestThunk<WithdrawPapelInfoFields>(
      {
        method: 'get',
        url: `/api/v1/cekim/${id}`,
        params: {
          hidden,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
