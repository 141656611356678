import { routes } from '../../routes/routes';
import Dashboard from '../../pages/Dashboard/Dashboard';

interface MenuItem {
  icon: string;
  label: string;
  route: string;
  children?: MenuItem[];
}

const menuItems: MenuItem[] = [
  {
    icon: 'las la-home',
    label: 'Dashboard',
    route: routes.dashboard,
  },
  {
    icon: 'las la-users',
    label: 'Yeni Yatırımlar',
    route: 'deposits', // Parent items don't have their own route
    children: [
      {
        icon: 'las la-magnet',
        label: 'Yeni Havale Yatırımları',
        route: routes.yenihavale,
      },
      {
        icon: 'las la-magnet',
        label: 'Yeni Papara Yatırımları',
        route: routes.yenipapara,
      },
      {
        icon: 'las la-magnet',
        label: 'Yeni Papel Yatırımları',
        route: routes.yenipapel,
      },
      {
        icon: 'las la-magnet',
        label: 'Yeni MFT Yatırımları',
        route: routes.yenimft,
      },
      {
        icon: 'las la-magnet',
        label: 'Yeni Kripto Yatırımları',
        route: routes.yenikripto,
      },
      {
        icon: 'las la-magnet',
        label: 'Yeni Payco Yatırımları',
        route: routes.yenipayco,
      },
      {
        icon: 'las la-magnet',
        label: 'Yeni Payfix Yatırımları',
        route: routes.yenipayfix,
      },
      {
        icon: 'las la-magnet',
        label: 'Yeni Kredi Kart Yatırımları',
        route: routes.yenikredikart,
      },
    ],
  },
  {
    icon: 'las la-users',
    label: 'Yeni Çekimler',
    route: 'withdraws', // Parent items don't have their own route
    children: [
      { 
        icon: 'las la-share-alt', 
        label: 'Havale Çekimler', 
        route: routes.havaleCekimler 
      },
      { 
        icon: 'las la-share-alt', 
        label: 'Papara Çekimler', 
        route: routes.paparaCekimler 
      },
      { 
        icon: 'las la-share-alt', 
        label: 'Papel Çekimler', 
        route: routes.papelCekimler 
      },
      { 
        icon: 'las la-share-alt', 
        label: 'MFT Çekimler', 
        route: routes.mftCekimler 
      },
      { 
        icon: 'las la-share-alt', 
        label: 'Kripto Çekimler', 
        route: routes.kriptoCekimler 
      },
      { 
        icon: 'las la-share-alt', 
        label: 'Kredi Kart Çekimler', 
        route: routes.kredikartCekimler 
      },
      { 
        icon: 'las la-share-alt', 
        label: 'Payco Çekimler', 
        route: routes.paycoCekimler
      },
      { 
        icon: 'las la-share-alt', 
        label: 'Payfix Çekimler', 
        route: routes.payfixCekimler
      },
    ]
  },
  {
    icon: 'las la-magnet',
    label: 'Virman',
    route: routes.virman,
  },
  { 
    icon: 'las la-chart-bar', 
    label: 'Raporlar', 
    route: routes.raporlar 
  },
  {
    icon: 'las la-home',
    label: 'Site Cash-In/Cash-Out',
    route: routes.sites,
  },
  {
    icon: 'las la-home',
    label: 'User Grup',
    route: routes.userGroups,
  },
  {
    icon: 'las la-university',
    label: 'Yatırımlar',
    route: routes.yatirimlar,
  },
  
  /*{
    icon: 'las la-tachometer-alt',
    label: 'Güncel Paparalar',
    route: routes.guncelPaparalar,
  },*/
  {
    icon: 'las la-briefcase',
    label: 'Kasalar',
    route: 'kasalar',
    children: [
      {
        icon: 'las la-briefcase',
        label: 'Banka Kasalar',
        route: routes.bankaKasalar,
      },
      {
        icon: 'las la-briefcase',
        label: 'Papara Kasalar',
        route: routes.paparaKasalar,
      },
      {
        icon: 'las la-briefcase',
        label: 'Papel Kasalar',
        route: routes.papelKasalar,
      },
      {
        icon: 'las la-briefcase',
        label: 'Payfix Kasalar',
        route: routes.payfixKasalar,
      },
      {
        icon: 'las la-briefcase',
        label: 'Payco Kasalar',
        route: routes.paycoKasalar,
      },
      {
        icon: 'las la-briefcase',
        label: 'MFT Kasalar',
        route: routes.mftKasalar,
      },
      {
        icon: 'las la-briefcase',
        label: 'Kripto Kasalar',
        route: routes.kriptoKasalar,
      },
    ]
  },
  /*{
    icon: 'las la-envelope',
    label: 'Papara Mailler',
    route: routes.paparaMailler,
  },*/
  {
    icon: 'las la-plus',
    label: 'Manüel Yatırım Oluştur',
    route: routes.manuelYatirimOlustur,
  },
  {
    icon: 'las la-minus',
    label: 'Manüel Çekim Oluştur',
    route: routes.manuelCekimOlustur,
  },
  {
    icon: 'las la-home',
    label: 'Merchantlar',
    route: routes.merchantlar,
  },
  {
    icon: 'las la-users',
    label: 'Kullanıcılar',
    route: routes.kullanicilar,
  },
  { icon: 'las la-cubes', label: 'Yetkiler', route: routes.yetkiler },
  /*{ icon: 'las la-code', label: 'API Gönder', route: '/api-gonder' },*/
  {
    icon: 'las la-ban',
    label: 'Fraud Listesi',
    route: routes.fraudListesi,
  },
  {
    icon: 'las la-key',
    label: 'Şifre Değiştir',
    route: routes.sifreDegistir,
  },
];

export default menuItems;
